import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Reel = () => {
  const data = useStaticQuery(graphql`
    query ReelQuery {
      allContentfulVideo(limit: 1) {
        edges {
          node {
            contentfulid
          }
        }
      }
    }
  `)

  const id = data.allContentfulVideo.edges[0].node.contentfulid

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        paddingTop: "56.25%",
        margin: "0 1vw 1vh 1vw",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: 0,
        }}
        src={`https://player.vimeo.com/video/${id}`}
        title="Tara Segda Reel"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )
}

export default Reel
