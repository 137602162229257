import React from "react"
import Layout from "../layouts/Layout"
import Reel from "../components/Reel"
import SiteMetadata from "../components/SiteMetadata"

const ReelPage = () => (
  <Layout>
    <SiteMetadata title="Reel" description="Tara Segda Reel" />
    <div className="bg-gray-100 pt-12 pb-12 lg:pb-16">
      <section className="container">
        <Reel />
      </section>
    </div>
  </Layout>
)

export default ReelPage
